import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Tooltip, Card, Table, TableBody, TableCell, TableHead, TableRow, Typography, TextField, FormControl, Grid } from '@mui/material';
import { connect } from "react-redux";
import Select from 'react-select';
import { useNavigate } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import SnoozeIcon from '@mui/icons-material/Snooze';
import { createSchedule, deleteSchedule, getScheduleList, updateSchedule } from "../../redux/actions/schedular";
import { getCronTabList } from '../../redux/actions/crontab';
import { getClockedList } from '../../redux/actions/clocked';
import { getSuitesList } from '../../redux/actions/suites';
import { getAllTestCases } from "../../redux/actions/testcase";
import Divider from "@mui/material/Divider";
import '../../styles/custom.css';
import { CardContent } from '@material-ui/core';
import { toast } from 'react-toastify';
import { set } from 'date-fns';

const Scheduler = ({ getSuitesList, getClockedList, getCronTabList, createSchedule, deleteSchedule, getAllTestCases, allTestCasesList, getScheduleList, updateSchedule, cronTabList, scheduleList, ClockedList, suitesList }) => {
    const [name, setName] = useState('');
    const [recipient, setrecipient] = useState('');
    const [task, setTask] = useState(null);
    const [args, setArgs] = useState(null);
    const [enabled, setEnabled] = useState(true);
    const [showEditButton, setShowEditButton] = useState(false);
    const [scheduleType, setscheduleType] = useState(null);
    const [schedule, setschedule] = useState(null);
    const [Schedular, setSchedular] = useState('');
    const navigate = useNavigate();
    const [jobOptions, setJobOptions] = useState([]);
    const [schedulerOptions, setSchedulerOptions] = useState([]);
    const scheduleTypeList = [
        { value: 'Crontab Schedule', label: 'Crontab Schedule' },
        { value: 'Clocked Schedule', label: 'Clocked Schedule' },
    ];

    const taskTypeList = [
        { value: 'TestCase', label: 'Test Case' },
        { value: 'TestSuite', label: 'Test Suite' },
    ];

    useEffect(() => {
        getScheduleList();
        getCronTabList(0, 0, null, null, JSON.stringify({}));
        getAllTestCases(0, 0, null, null, JSON.stringify({}),null);
        getClockedList(0, 0, null, null, JSON.stringify({}));
        getSuitesList(0, 100, null, null, JSON.stringify({}));
    }, []);

    const handleTaskChange = (e) => {
        setTask(e.target.value);
    };

    const create = () => {
        setShowEditButton(false);
        if (!name || !task?.value || !args?.value || !scheduleType?.value || !schedule.value) {
            toast.error("All fields are mandatory");
            return;
        } else {
            const obj = {
                name: name,
                task: task?.value,
                args: args?.value,
                enabled: enabled,
                schedular_type: scheduleType?.value,
                schedule: schedule?.value,
                recipient: recipient
            }
            createSchedule(obj)
                .then(() => {
                    setName('');
                    setrecipient('');
                    setTask('select');
                    setArgs('select');
                    setschedule('select');
                    setscheduleType('select');
                    setEnabled(true);
                    setSchedular('')
                    getScheduleList();
                    toast.success("Schedule Job Created ")
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    };

    // Edit Function
    const updateData = () => {
        if (!name || !task?.value || !args?.value || !scheduleType?.value || !schedule.value) {
            toast.error("All fields are mandatory");
            return;
        }
        else {
            const updated = {
                id: Schedular.id,
                name: name,
                task: task?.value,
                args: args?.value,
                enabled: enabled,
                schedular_type: scheduleType?.value,
                schedule: schedule.value,
                recipient: recipient
            }
            setSchedular(updated)
            updateSchedule(updated).then(()=>{
                toast.success("Schedule Job Updated Sucessfully ")
            }).catch((error) => {
                console.error('Error:', error);
            });;
            setShowEditButton(false);
            setName('');
            setrecipient('');
            setTask('select');
            setArgs('select');
            setschedule('select')
            setscheduleType('select');
            setEnabled(true);
            setShowEditButton(false);
            getScheduleList();
        }
    }


    const editButton = (obj) => {
        setShowEditButton(true);
        setName(obj.name);
        setrecipient(obj.recipient);
        setEnabled(obj.enabled);
        setscheduleType({ value: obj.schedular_type, label: obj.schedular_type })
        setSchedular(obj);

        if (obj.schedular_type === 'Crontab Schedule') {
            createDropDownList(cronTabList, 'Crontab Schedule');
            let data = cronTabList?.find(list => list.cron_id === obj.schedular);
            setschedule({ value: obj.schedular, label: data?.name });
        }
        else {
            createDropDownList(ClockedList, 'Clocked Schedule');
            let data = ClockedList?.find(list => list.clk_id === obj.schedular);
            setschedule({ value: obj.schedular, label: data?.name });
        }

        if (obj.task === 'TestSuite') {
            setTask({ value: obj.task, label: 'Test Suite' });
            createDropDownList(suitesList, 'TestSuite');
            let data = suitesList?.find(testCase => testCase.suite_id === obj.args);
            setArgs({ value: obj.args, label: data?.suite_name });
        }
        else {
            setTask({ value: obj.task, label: 'Test Case' });
            createDropDownList(allTestCasesList, 'TestCase');
            let data = allTestCasesList?.find(testCase => testCase.case_id === obj.args);
            setArgs({ value: obj.args, label: data?.case_name });
        }
    }

    const cancelClicked = () => {
        setName('');
        setrecipient('');   
        setTask('select');
        setArgs('select');
        setschedule('select')
        setscheduleType('select');
        setEnabled(true);
        setShowEditButton(false);
        getScheduleList();
    };

    // Delete Schedule
    const deleteButton = (row) => {
        deleteSchedule(row.id)
            .then(() => {
                getScheduleList();
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const handleIsEnabled = (event) => {
        setEnabled(event.target.checked);
    };

    const onChangeName = (event) => {
        setName(event.target.value);
    };
    const onChangerecipient = (event) => {  setrecipient(event.target.value); };

    const handleScheduleTypeChange = (event) => {
        if (event.value === 'Crontab Schedule') {
            createDropDownList(cronTabList, 'Crontab Schedule');
        }
        else {
            createDropDownList(ClockedList, 'Clocked Schedule');
        }
        setscheduleType(event);
    }

    const handleTaskTypeChange = (event) => {
        setTask(event);
        if (event.value === 'TestCase') {
            createDropDownList(allTestCasesList, 'TestCase');
        }
        else {
            createDropDownList(suitesList, 'TestSuite');
        }
    }

    const handleJobTypeChange = (event) => {
        setArgs(event);
    }

    const createDropDownList = (list, changeType) => {
        let flagArray = [];
        if (changeType === "TestCase") {
            if (list) {
                list?.forEach((item) => {
                    let obj = {
                        value: item.case_id,
                        label: item.case_name
                    }
                    flagArray.push(obj);
                });
            }
            setJobOptions(flagArray);
        }
        if (changeType === "TestSuite") {
            if (list) {
                list?.forEach((item) => {
                    let obj = {
                        value: item.suite_id,
                        label: item.suite_name
                    }
                    flagArray.push(obj);
                });
            }
            setJobOptions(flagArray);
        }
        if (changeType === "Crontab Schedule") {
            if (list) {
                list?.forEach((item) => {
                    let obj = {
                        value: item.cron_id,
                        label: item.name
                    }
                    flagArray.push(obj);
                });
            }
            setSchedulerOptions(flagArray);
        }
        if (changeType === "Clocked Schedule") {
            if (list) {
                list?.forEach((item) => {
                    let obj = {
                        value: item.clk_id,
                        label: item.name
                    }
                    flagArray.push(obj);
                });
            }
            setSchedulerOptions(flagArray);
        }
    };

    const handleSchedularChange = (event) => {
        setschedule(event);
    }

    const navigateToCrontab = () => {
        navigate('/crontab');
    };

    const navigateToClockTask = () => {
        navigate('/clock-task');
    };

    return (
        <div>
            <Card
                raised
                sx={{
                    minWidth: "100%",
                    overflowX: "auto"  // Enable scrolling
                }}>
                <Typography variant="h5" sx={{
                    p: 2
                }}>
                     Schedule New Task
                </Typography>
                <Divider></Divider>

                <CardContent>
                    <Grid container spacing={2} sx={{ p: 2 }}>
                        <Grid item xs={4} sm={2}>
                            <Typography variant="subtitle2" fontSize={14}>
                                Name:
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sm={10}>
                            <Typography variant="subtitle2" fontSize={14}>
                                <TextField className='input' value={name} onChange={onChangeName}
                                    style={{ width: '39%' }} required />
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <Typography variant="subtitle2" fontSize={14}>
                                Task Type:
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sm={4}>

                            <FormControl fullWidth>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    name="name"
                                    options={taskTypeList}
                                    value={task}
                                    onChange={handleTaskTypeChange}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                                        container: base => ({ ...base, width: '100%' }),
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <Typography variant="subtitle2" fontSize={14}>
                                Schedule Type:
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            <FormControl fullWidth>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    name="name"
                                    options={scheduleTypeList}
                                    value={scheduleType}
                                    onChange={handleScheduleTypeChange}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                                        container: base => ({ ...base, width: '100%' }),
                                    }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={4} sm={2}>
                            <Typography variant="subtitle2" fontSize={14}>
                                Job :
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sm={4}>

                            <FormControl fullWidth>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    name="name"
                                    isDisabled={!task?.value}
                                    value={args}
                                    options={jobOptions}
                                    onChange={handleJobTypeChange}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                                        container: base => ({ ...base, width: '100%' }),
                                    }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={4} sm={2}>
                            <Typography variant="subtitle2" fontSize={14}>
                                Schedular :
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sm={4}>

                            <FormControl fullWidth>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    name="name"
                                    isDisabled={!scheduleType?.value}
                                    value={schedule}
                                    options={schedulerOptions}
                                    onChange={handleSchedularChange}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                                        container: base => ({ ...base, width: '100%' }),
                                    }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={4} sm={2}>
                            <Typography variant="subtitle2" fontSize={14}>
                                Enabled:
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sm={8}>
                            <Checkbox className="form-check-input" checked={enabled} value={enabled}
                                onChange={handleIsEnabled} />
                            <span> Set to False to disable the Schedule</span>
                        </Grid>


                    </Grid>
                    <Grid container spacing={2} sx={{ p: 2 }}>
                        <Grid item xs={4} sm={2}>
                            <Typography variant="subtitle2" fontSize={14}>
                                Recipient Name:
                            </Typography>(optional)
                        </Grid>
                        <Grid item xs={4} sm={10}>
                            <Typography variant="subtitle2" fontSize={14}>
                                <TextField className='input' value={recipient} onChange={onChangerecipient}
                                    style={{ width: '100%' }} multiline />
                            </Typography>
                           <span>  <h6 >Note:- use comma (,) sperator for multiple recipients</h6></span>
                        </Grid>
                    </Grid>
                    <div className="card-form-btn marginTop">
                        <Tooltip title="Clocked List"><Button onClick={navigateToClockTask} size={"small"}><AccessAlarmIcon /> Clocked List</Button></Tooltip>
                        <Tooltip title=" Crontab List"><Button onClick={navigateToCrontab} size={"small"}><SnoozeIcon /> Crontab List</Button></Tooltip>

                        {!showEditButton ? <Button onClick={create} className="btn btn-inverse-success right">Save</Button> :
                            <Button onClick={updateData} className="modalButton right" >Update</Button>}
                        <Button colorStyle='cancel' className="btn btn-inverse-danger right" onClick={cancelClicked}>Cancel</Button>
                    </div>
                </CardContent>
            </Card>
            <br />

            <Card
                raised
                sx={{
                    minWidth: "100%",
                    overflowX: "auto"  // Enable scrolling
                }}>
                <Typography variant="h5" sx={{
                    p: 2
                }}>
                    Scheduled Task List
                </Typography>

                <Divider></Divider>
                <CardContent>
                    <div className="table-responsive">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Task Name</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Job Name</TableCell>
                                    <TableCell>Is Enabled</TableCell>
                                    <TableCell>Schedule Type</TableCell>
                                    <TableCell>Schedule</TableCell>
                                    <TableCell>Run Count</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {scheduleList?.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.task}</TableCell>
                                        <TableCell>{row.suite_id ? row.suite_name : row.testcase_name}</TableCell>
                                        <TableCell>{row.enabled ? 'True' : 'False'}</TableCell>
                                        <TableCell>{row.schedular_type}</TableCell>
                                        <TableCell>{row.crontab ? row.crontab_name : row.clocked_name}</TableCell>
                                        <TableCell>{row.run_count}</TableCell>
                                        <TableCell>
                                            <Tooltip title="Edit"><EditIcon className="action-icon" onClick={() => editButton(row)} /></Tooltip>
                                            <Tooltip title="Delete"><DeleteIcon style={{ color: '#d71b1b' }} className="action-icon clickIconClassDelete" onClick={() => deleteButton(row)} /></Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>


                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

const mapStateToProps = (state) => ({
    cronTabList: state.cronTab?.crontabList?.rows,
    allTestCasesList: state?.auth?.allTestCasesList?.rows,
    scheduleList: state.schedular.listSchedule?.rows,
    ClockedList: state?.clocked?.clockedList?.rows,
    suitesList: state?.suites?.suitesList?.rows,
});

const mapDispatchToProps = {
    getSuitesList,
    getClockedList,
    getCronTabList,
    getAllTestCases,
    createSchedule,
    deleteSchedule,
    getScheduleList,
    updateSchedule
};

export default connect(mapStateToProps, mapDispatchToProps)(Scheduler);
