import React, { useState, useEffect } from 'react';
import { Card, CardContent, Button, FormControl, FormHelperText, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography,Link } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Modal from '@mui/material/Modal';
import { modalPopUpStyleLarge } from '../../styles/getLPTheme';
import Box from '@mui/material/Box';
import { toast } from 'react-toastify';
import { Link as RouterLink}  from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ENV_VAR from "../../common";
import StopIcon from '@mui/icons-material/Stop';
import ErrorIcon from '@mui/icons-material/Error';
import Divider from "@mui/material/Divider";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { connect } from "react-redux";
import DateFormatter from '../../components/DateFormatter';
import { getCronTabList } from '../../redux/actions/crontab';
import { getClockedList } from '../../redux/actions/clocked';
import { getSuitesList } from '../../redux/actions/suites';
import { getAllTestCases } from "../../redux/actions/testcase";
import { SchedularJobReport } from "../../redux/actions/schedularReport";
import Select from 'react-select';
import '../../styles/common.css';
import '../../styles/custom.css';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import DeleteIcon from '@mui/icons-material/Delete';
import showConfirmationAlert from "../../components/Confirm";
import TestJobService from '../../services/testJob.service'
import SuiteJobService from '../../services/suiteReport.service'

import { getTestJobs, getSuiteTestJobs } from "../../redux/actions/testCaseReport";

function SchedularReport({ getTestJobs,getSuiteTestJobs,suiteJobList,listOfJob,SchedularJobReport,getSuitesList, getClockedList, getCronTabList, getAllTestCases, allTestCasesList, cronTabList, ClockedList, suitesList,getSuitesTestCaseForDetailedReport ,getSuiteReport }) {
    const navigate = useNavigate();
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [error, setError] = useState('');
    const [scheduleType, setscheduleType] = useState(null);
    const [schedule, setschedule] = useState(null);
    const [openRows, setOpenRows] = React.useState({});
    const [schedularTestcaseList, setschedularTestcaseList] = useState('');
    const [schedularSuiteList, setschedularSuiteList] = useState('');
    const [testCase, setTestCase] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [image, setImage] = useState('')
    const [token, setToken] = useState(localStorage.getItem("token"));
    const [task, setTask] = useState('TestCase');
    const [args, setArgs] = useState(null);
    const [jobOptions, setJobOptions] = useState([]);
    const [open_video, setOpenVideo] = React.useState(false);
    const [video, setVideo] = useState('')


    const [schedulerOptions, setSchedulerOptions] = useState([]);
    const scheduleTypeList = [
        { value: 'Crontab Schedule', label: 'Crontab Schedule' },
        { value: 'Clocked Schedule', label: 'Clocked Schedule' },
    ];

    const taskTypeList = [
        { value: 'TestCase', label: 'Test Case' },
        { value: 'TestSuite', label: 'Test Suite' },
    ];

    const navigateToSuiteTestCase = (row) => {
        navigate('/SuiteTestCaseReport', { state : row});
      }

    const handleClose = () => {
        setOpen(false);
      };

    useEffect(() => {
        getCronTabList(0, 0, null, null, JSON.stringify({}));
        getAllTestCases(0, 0, null, null, JSON.stringify({}),null);
        getClockedList(0, 0, null, null, JSON.stringify({}));
        getSuitesList(0, 100, null, null, JSON.stringify({}));
        
    }, []);

    useEffect(() => {
        getTestJobs();
        // getSuiteTestJobs();
 
    }, []);

    useEffect(() => {
        setTestCase(listOfJob);
        
    }, [listOfJob])



    const handleSubmit = (event) => {
        event.preventDefault();
        setschedularTestcaseList('');
        setschedularSuiteList('');
        if (fromDate?.isAfter(toDate)) {
            toast.error('From Date cannot be greater than To Date.');
            return;
        }
        if ((fromDate && !toDate) || (!fromDate && toDate)) {
            toast.error('Both date ranges are mandatory');
            return;
        }else if(task.value==null){

            toast.error('Job type is  mandatory');
        }else if(args?.value==null){
            toast.error('Job Name is mandatory');  
        }
        else {
            setError('');
            let obj = {
                from: fromDate ? fromDate.format('YYYY-MM-DD') : null,
                to: toDate ? toDate.format('YYYY-MM-DD') : null,
                id: args? args.value:null,
                job_type:task ? task.value: null,
                schedular_type:scheduleType? scheduleType.value:null,
                schedular:schedule ?  schedule.value: null
            };
            
            SchedularJobReport(obj).
            then(res => {
               
                if(res && res?.length>0){
                    if(obj.job_type==="TestCase"){
                        setschedularSuiteList(null)
                        setschedularTestcaseList(res)
                        
                    }else{
                        setschedularSuiteList(res)
                        setschedularTestcaseList(null)
                    }
                    toast.success('Data Fetched Sucessfully');
                    
                }else{
                    toast.success('No Record Found');
                }
                
                 
            });
        }
    };

    const handleSchedularChange = (event) => {
        
        setschedule(event);
    }
    const handleJobTypeChange = (event) => {
        setArgs(event);
    }

    const handleScheduleTypeChange = (event) => {
        setschedule(null)
        setSchedulerOptions('');
        if (event.value === 'Crontab Schedule') {
            
            createDropDownList(cronTabList, 'Crontab Schedule');
        }
        else {

            createDropDownList(ClockedList, 'Clocked Schedule');
        }
        setscheduleType(event);
        
    }


    const handleTaskTypeChange = (event) => {
        setTask(event);


        if (event.value === 'TestCase') {
            createDropDownList(allTestCasesList, 'TestCase');
            setArgs(null);
            setSchedulerOptions(null);
            setscheduleType(null)
            setschedularSuiteList(null);
            setschedularTestcaseList(null);
        }
        else {
            createDropDownList(suitesList, 'TestSuite');
            setArgs(null);
            setscheduleType(null)
            setSchedulerOptions(null);
            setschedularSuiteList(null);
            setschedularTestcaseList(null);
        }
    }
    const createDropDownList = (list, changeType) => {
        let flagArray = [];
        if (changeType === "TestCase") {
            if (list) {
                list?.forEach((item) => {
                    let obj = {
                        value: item.case_id,
                        label: item.case_name
                    }
                    flagArray.push(obj);
                });
            }
            setJobOptions(flagArray);
        }
        if (changeType === "TestSuite") {
            if (list) {
                list?.forEach((item) => {
                    let obj = {
                        value: item.suite_id,
                        label: item.suite_name
                    }
                    flagArray.push(obj);
                });
            }
            setJobOptions(flagArray);
        }
        if (changeType === "Crontab Schedule") {
            if (list) {
                list?.forEach((item) => {
                    let obj = {
                        value: item.cron_id,
                        label: item.name
                    }
                    flagArray.push(obj);
                });
            }
            setSchedulerOptions(flagArray);
        }
        if (changeType === "Clocked Schedule") {
            if (list) {
                list?.forEach((item) => {
                    let obj = {
                        value: item.clk_id,
                        label: item.name
                    }
                    flagArray.push(obj);
                });
            }
            setSchedulerOptions(flagArray);
        }
    };

    const truncateText = (text, maxLength) => {
        if (text?.length <= maxLength) {
            return text;
        }
        return text?.substring(0, maxLength) + '...';
    };

    const toggleRow = (id) => {
        setOpenRows((prevState) => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    const updatePopUp = (image) => {
        setOpen(true);
        setImage(image)
    }
    const updatePopUpVideo = (video) => {
        setOpenVideo(true);
        setVideo(video)
    }
    const handleVideoClose = () => {
        setOpenVideo(false);
    };
    const navigateToJira = (row,row2) => {
        const obj = {
            message:row.message,
            stepName:row.step_name,
            TestcaseName:row2.test_case_name,
            video:row2.video_url,
            image:row.screenshot,
        }
       
        navigate('/Jira', { state: obj });
      }
    const renderChildTable = (parentRowId) => {
        var parentRecord;
            parentRecord = schedularTestcaseList?.find((row) => row.id === parentRowId);
        if (openRows[parentRowId] && parentRecord) {
            const childRecords = parentRecord.sellogs;
            return (
                <Table>
                    <TableHead>
                        <TableRow className='th' >
                            <TableCell >Screenshot</TableCell>
                            <TableCell >Step Name</TableCell>
                            <TableCell >Log Type</TableCell>
                            <TableCell >Status</TableCell>
                            <TableCell >Message</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {childRecords.map((record) => (
                            <TableRow key={record.id}>
                                <TableCell>{record.screenshot && <RouterLink onClick={() => updatePopUp(record)} ><img src={`${process.env.REACT_APP_API_URL}/api/v1/getScreenshot/${record.id}?token=${token}`} alt="Screenshot" style={{ maxWidth: '100px' }} /></RouterLink>}</TableCell>
                                {/* <TableCell>{record.screenshot && <Link onClick={() => updatePopUp(record)} ><img src={ENV_VAR.API_HOST + record.screenshot} alt="Screenshot" style={{ maxWidth: '100px' }} /></Link>}</TableCell> */}
                                <Tooltip title={record.step_name}><TableCell>{truncateText(record.step_name, 20)}</TableCell></Tooltip>
                                <TableCell>{record.log_type}</TableCell>
                                <TableCell>
                                    {record.status === 'running' ? (<div className="badge badge-opacity-warning text-capitalize">{record.status}</div>) :
                                        record.status === 'stop' ? (<div className="badge badge-opacity-warning text-capitalize">{record.status}<StopIcon style={{ color: '#FF0000' }} /></div>) :
                                            record.status === 'error' ? (<div className="badge badge badge-danger text-capitalize">{record.status}<ErrorIcon style={{ color: '#B22222' }} /></div>) :
                                                record.status === 'failure' ? (<div className="badge badge-danger text-capitalize">{record.status}<ErrorIcon style={{ color: '#B22222' }} /></div>) :
                                                    (<div className="badge badge-opacity-success text-capitalize">{record.status}<CheckCircleIcon style={{ color: '#008000' }} /></div>)}
                                </TableCell>
                                <TableCell className="text-break" style={{ textAlign: 'left', wordWrap: 'break-word', whiteSpace: 'normal' }}>{record.message}</TableCell>
                                <TableCell > 
                {record.status === 'error' || record.status === 'failure' ?
                  <Button onClick={() => (navigateToJira(record,parentRecord))} colorStyle="cancel"> Create Ticket</Button>:''}
                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            );
        }
        return null;
    };

    const handleReset = () => {
        setFromDate(null);
        setToDate(null);
        setTask('');
        setArgs(null);
        setschedule(null);
        setscheduleType(null);
        setSchedulerOptions([]);
        setJobOptions([]);
        setschedularSuiteList('');
        setschedularTestcaseList(null);
        
    };

    const deleteJob = (row) => {
        console.log(row);
    
        showConfirmationAlert('Are you sure you want to Delete?')
            .then((result) => {
                if (result.isConfirmed) {
                    
                    let deleteJobPromise;
    
                    if (task.value === 'TestCase') {
                        deleteJobPromise = TestJobService.deleteTestJobList(row.id);
                    } else {
                        deleteJobPromise = SuiteJobService.deleteSuiteJob(row.id);
                    }
    
                    deleteJobPromise
                        .then((res) => {
                            toast.success(res.data.message);
                            let obj = {
                                from: fromDate ? fromDate.format('YYYY-MM-DD') : null,
                                to: toDate ? toDate.format('YYYY-MM-DD') : null,
                                id: args ? args.value : null,
                                job_type: task ? task.value : null,
                                schedular_type: scheduleType ? scheduleType.value : null,
                                schedular: schedule ? schedule.value : null
                            };
                            return SchedularJobReport(obj);
                        })
                        .then((res) => {
                            if (res && res?.length > 0 ) {
                                if (task.value === "TestCase") {
                                    setschedularTestcaseList(res);
                                } else {
                                    setschedularSuiteList(res);
                                }
                            }else{
                                setschedularTestcaseList(null);
                                setschedularSuiteList(null);
                                toast.success("No record Found")
                            }
                        })
                        .catch((error) => {
                            console.error('Error:', error);
                            toast.error('Something went wrong. Please try again.');
                        })
                        
                }
            });
    };
    
    

    const tableRows = schedularTestcaseList?.length > 0 ? schedularTestcaseList.map((row, index) => (
        <React.Fragment key={row.id}>
            <TableRow>
                <Tooltip title={row.test_case_name}>
                    <TableCell>{truncateText(row.test_case_name, 30)}</TableCell>
                </Tooltip>
                <TableCell>{row.total_steps}</TableCell>
                <TableCell><DateFormatter date={row.execution_time}/></TableCell>
                <TableCell><DateFormatter date={row.completion_time}/></TableCell>
                <TableCell>
                    {row.status === 'running' ? (
                        <div className="badge badge-opacity-warning text-capitalize">{row.status}</div>
                    ) : row.status === 'stopped' ? (
                        <div className="badge badge-opacity-warning text-capitalize">
                            {row.status}
                            <StopIcon style={{ color: '#FF0000' }} />
                        </div>
                    ) : row.status === 'error' ? (
                        <div className="badge badge-danger text-capitalize">
                            {row.status}
                            <ErrorIcon style={{ color: '#B22222' }} />
                        </div>
                    ) : row.status === 'failure' ? (
                        <div className="badge badge-danger text-capitalize">
                            {row.status}
                            <ErrorIcon style={{ color: '#B22222' }} />
                        </div>
                    ) : (
                        <div className="badge badge-opacity-success text-capitalize">
                            {row.status}
                            <CheckCircleIcon style={{ color: '#008000' }} />
                        </div>
                    )}
                </TableCell>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => toggleRow(row.id)}
                    >
                        {openRows[row.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
            {row.video && (
              <RouterLink onClick={() => updatePopUpVideo(row)} title="Play">
                <SmartDisplayIcon style={{ color: 'red', cursor: 'pointer' }}></SmartDisplayIcon>
              </RouterLink>
            )}
          </TableCell>
          {row.status !== 'running' &&(
            <TableCell>
            <Tooltip title="Delete">
                <DeleteIcon style={{ color: '#d71b1b' }} onClick={() => deleteJob(row)} className="action-icon clickIconClassDelete" />
            </Tooltip>
            </TableCell>
            )}
            </TableRow>
            <TableRow>
                <TableCell colSpan={8}>
                    {renderChildTable(row.id)}
                </TableCell>
            </TableRow>
        </React.Fragment>
    )) : (
        <TableRow>
            <TableCell colSpan={8} align="center">
                <span className='marginTop text-color-grey'>No Report available.</span>
            </TableCell>
        </TableRow>
    );
    console.log("Suite",schedularSuiteList)
    const tableRows2 = schedularSuiteList?.length > 0 ? schedularSuiteList?.map((row, index) => (
        <TableRow className='td'>
          <TableCell>{row.suite_name}</TableCell>
          <TableCell>{row.total_test_cases}</TableCell>
          <TableCell><DateFormatter date={row.execution_time}/></TableCell>
          <TableCell><DateFormatter date={row.completion_time}/></TableCell>
          <TableCell>
                      {row.status === 'running' ? (<div className="badge badge-opacity-warning text-capitalize">{row.status}</div>) :
                      row.status === 'stopped' ? (<div className="badge badge-opacity-warning text-capitalize">{row.status}<StopIcon style={{ color: '#FF0000' }} /></div> ): 
                      row.status === 'error' ? (<div className="badge badge badge-danger text-capitalize">{row.status}<ErrorIcon style={{ color: '#B22222' }} /></div>) :
                      row.status === 'failure' ? (<div className="badge badge-danger text-capitalize">{row.status}<ErrorIcon style={{ color: '#B22222' }} /></div>) : 
                      (<div className="badge badge-opacity-success text-capitalize">{row.status}<CheckCircleIcon style={{ color: '#008000' }} /></div>)} 
            </TableCell>
          <TableCell><Link onClick={ () => (navigateToSuiteTestCase(row))}>View Details</Link></TableCell>
          <TableCell>
            {row.video_url && (
              <RouterLink onClick={() => updatePopUpVideo(row)} title="Play">
                <SmartDisplayIcon style={{ color: 'red', cursor: 'pointer' }}></SmartDisplayIcon>
              </RouterLink>
            )}
          </TableCell>
          {row.status !== 'running' &&(
            <TableCell>
            <Tooltip title="Delete">
                <DeleteIcon style={{ color: '#d71b1b' }} onClick={() => deleteJob(row)} className="action-icon clickIconClassDelete" />
            </Tooltip>
            </TableCell>
            )}
        </TableRow>
      )) : (
        <TableRow>
            <TableCell colSpan={8} align="center">
                <span className='marginTop text-color-grey'>No Report available.</span>
            </TableCell>
        </TableRow>
    );


    return (
        <div>
            <Card
                raised
                sx={{
                    minWidth: "100%",
                    overflowX: "auto"  // Enable scrolling
                }}>
                <Typography variant="h5" sx={{
                    p: 2
                }}>
                    Schedule Job Report
                </Typography>
                <Divider></Divider>

                <CardContent>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <form onSubmit={handleSubmit} className='flex-container'>
                            <Grid container spacing={2} sx={{ p: 2 }}>


                                <Grid item xs={4} sm={2}>
                                    <Typography variant="subtitle2" fontSize={14}>
                                        Job Type:
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} sm={4}>

                                    <FormControl fullWidth>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            name="name"
                                            options={taskTypeList}
                                            value={task}
                                            onChange={handleTaskTypeChange}
                                            menuPortalTarget={document.body}
                                            styles={{
                                                menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                container: base => ({ ...base, width: '100%' }),
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <Typography variant="subtitle2" fontSize={14}>
                                        Schedule Type:
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} sm={4}>
                                    <FormControl fullWidth>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            name="name"
                                            options={scheduleTypeList}
                                            value={scheduleType}
                                            onChange={handleScheduleTypeChange}
                                            menuPortalTarget={document.body}
                                            styles={{
                                                menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                container: base => ({ ...base, width: '100%' }),
                                            }}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={4} sm={2}>
                                    <Typography variant="subtitle2" fontSize={14}>
                                        Job Name :
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} sm={4}>

                                    <FormControl fullWidth>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            name="name"
                                            isDisabled={!task?.value}
                                            value={args}
                                            options={jobOptions}
                                            onChange={handleJobTypeChange}
                                            menuPortalTarget={document.body}
                                            styles={{
                                                menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                container: base => ({ ...base, width: '100%' }),
                                            }}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={4} sm={2}>
                                    <Typography variant="subtitle2" fontSize={14}>
                                        Schedular :
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} sm={4}>

                                    <FormControl fullWidth>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            name="name"
                                            isDisabled={!scheduleType?.value}
                                            value={schedule}
                                            options={schedulerOptions}
                                            onChange={handleSchedularChange}
                                            menuPortalTarget={document.body}
                                            styles={{
                                                menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                container: base => ({ ...base, width: '100%' }),
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <Typography variant="subtitle2" fontSize={14}>
                                        From :
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} sm={4}>

                                    <FormControl fullWidth>
                                        <DatePicker error={Boolean(error)}
                                            value={fromDate}
                                            onChange={(newValue) => setFromDate(newValue)}
                                            dateFormat="yyyy-MM-dd"

                                        />
                                        {error && <FormHelperText>{error}</FormHelperText>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <Typography variant="subtitle2" fontSize={14}>
                                        To :
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} sm={4}>

                                    <FormControl fullWidth>
                                        <DatePicker error={Boolean(error)}
                                            value={toDate}
                                            onChange={(newValue) => setToDate(newValue)}
                                            dateFormat="yyyy-MM-dd"
                                            showTimeInput
                                        />
                                        {error && <FormHelperText>{error}</FormHelperText>}
                                    </FormControl>
                                </Grid>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                    <Grid container style={{ paddingTop: '8px', display: 'flex', flexDirection: 'row' ,justifyContent: 'flex-end'}}>
                                        <Grid item>
                                            <Button type="submit">Submit</Button>
                                            <Button onClick={handleReset} color="secondary">Reset</Button>
                                        </Grid>
                                    </Grid>
                                </div>

                            </Grid>
                        </form>

                    </LocalizationProvider>
                </CardContent>

            </Card>
            <br />

            <Card
                raised
                sx={{
                    minWidth: "100%",
                    overflowX: "auto"  // Enable scrolling
                }}>
                <Typography variant="h5" sx={{
                    p: 2
                }}>
                    Schedule Job Report
                </Typography>

                <Divider></Divider>
                <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                className="modalContainer">
                <Box
                    sx={{
                        ...modalPopUpStyleLarge, '& > :not(style)': { m: 1 },
                    }}
                    noValidate
                    autoComplete="off">

                    <div className="modal-header">
                        <img src={`${process.env.REACT_APP_API_URL}/api/v1/getScreenshot/${image.id}?token=${token}`} alt="Screenshot" style={{ maxWidth: '90%', height: 'auto' }} />
                        {/* <img src={ENV_VAR.API_HOST + image.screenshot} alt="Screenshot" style={{ maxWidth: '90%', height: 'auto' }} /> */}
                    </div>

                    <div className="modal-footer">
                        <Button onClick={handleClose} className="modalButton modalCloseButton">Close</Button>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={open_video}
                onClose={handleVideoClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                className="modalContainer">
                <Box
                sx={{
                    ...modalPopUpStyleLarge, '& > :not(style)': { m: 1 },
                }}
                noValidate
                autoComplete="off">

                <div className="modal-header">
                    <video controls style={{ maxWidth: '100%' }}>
                    <source src={`${process.env.REACT_APP_API_URL}/api/v1/getVideo/${video.id}?token=${token}`} type="video/mp4" />
                    </video>
                </div>

                <div className="modal-footer">
                    <Button onClick={handleVideoClose} className="modalButton modalCloseButton">Close</Button>
                </div>
                </Box>
            </Modal>
            <CardContent className='marginTop'>
                <TableContainer component={Card}>
                    <Table className='dashboard-table  TableCell table-responsive '>
                        <TableHead >
                            <TableRow className='th'>
                                <TableCell >Job Name</TableCell>
                                <TableCell >Total Steps</TableCell>
                                <TableCell >Start Time</TableCell>
                                <TableCell >Completion Time</TableCell>
                                <TableCell >Status</TableCell>
                                <TableCell >Details</TableCell>
                                <TableCell >Video</TableCell>
                                <TableCell >Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {task.value === 'TestCase' ?tableRows: tableRows2}
                            
                        </TableBody>
                    </Table>

                </TableContainer>
            </CardContent>

            </Card>
        </div>
    );
}

const mapStateToProps = (state) => ({
    cronTabList: state.cronTab?.crontabList?.rows,
    allTestCasesList: state?.auth?.allTestCasesList?.rows,
    ClockedList: state?.clocked?.clockedList?.rows,
    suitesList: state?.suites?.suitesList?.rows,
    SchedularJobs:state?.SchedularJob?.Schedule_list,
    suiteJobList: state.testCaseReport?.suiteTestCaseList,
    listOfJob: state.testCaseReport?.testJobList,
});

const mapDispatchToProps = {
    getSuitesList,
    getClockedList,
    getCronTabList,
    getAllTestCases,
    SchedularJobReport,
    getTestJobs,
    getSuiteTestJobs,
};

export default connect(mapStateToProps, mapDispatchToProps)(SchedularReport);