import {Link, Card, CardContent, Button,Grid ,Box, Typography} from '@mui/material';
import { testCaseList, createTestCase, getAllTestCases ,deleteTestCaseById } from "../redux/actions/testcase";
import { connect } from "react-redux";
import Select from '@mui/material/Select';
import { useState, useEffect } from "react";
import {  useLocation, useNavigate } from "react-router-dom";
import { showErrorAlert} from './SweetAlert';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuItem from '@mui/material/MenuItem';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import ServerPaginationGridNoRowCount from "../components/Table/DataGrid";
import extractSortAndFilterParams from '../components/Table/filterSortParams';
import Divider from "@mui/material/Divider";
import * as React from 'react';
import '../styles/project.css';
import '../styles/custom.css';
import '../styles/common.css';
import { LabelRounded } from '@mui/icons-material';
import Search from '../components/Search'

export const AllTestCases = ({ getAllTestCases, allTestCasesList , deleteTestCaseById, testCount }) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [paginationModals, setPaginationModals] = useState({
    page: 0,
    pageSize: 10,
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [sortModels, setSortModels] = useState([]);
  const [filterModels, setFilterModels] = useState({ items: [] });
  const [tableHeight, setTableHeight] = useState(400);
  const [selectedtype, setSelectedtype] = useState('All');
  const rowHeight = 52;
  const updateTableHeight = (pageSize) => {
    const headerHeight = 56; // Approximate header height (adjust based on your actual header height)
    const footerHeight = 56; // Approximate footer height (adjust based on your actual footer height)
    const newHeight = headerHeight + footerHeight + rowHeight * pageSize;
    setTableHeight(newHeight);
  };
  useEffect(() => {
    const { sort, filters } = extractSortAndFilterParams(sortModels, filterModels);
    getAllTestCases(paginationModals.page, paginationModals.pageSize, sort.field, sort.sort,JSON.stringify(searchQuery),selectedtype);
  }, [paginationModals, sortModels,filterModels,searchQuery]
  )

  const navigateToAddScreen = (row) => {
    navigate('/add-testcases', { state: row });
  }
 
  const navigateToTestScreen = (row) => {
    navigate(`/testcase`, { state: row });
  }
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const deleteTestCase = (row) => {
    let obj = {
      id : row.case_id
    }
    deleteTestCaseById(obj)
    .then(response => {
      const { sort, filters } = extractSortAndFilterParams(sortModels, filterModels);
      getAllTestCases(paginationModals.page, paginationModals.pageSize, sort.field, sort.sort,JSON.stringify(searchQuery));
    })
    .catch(error => {
      showErrorAlert("An error occurred: " + error);
    });                          
  } 

  const navigateToRunJob = (row) =>{
    navigate(`/runJob`, {state: row});
  }

  
  const columns = [
    {
      field: "case_name",
      headerName: "Test Case Name",
      renderCell:(params) => (
        <Tooltip title={params.row.case_name}>
          <Link style={{ cursor: 'pointer' , fontWeight: 'bold'}} onClick={ () => (navigateToTestScreen(params.row))} title={params.row.case_name}> {params.row.case_name}</Link>
        </Tooltip>
      )
    },
    {
      field: "proj_name",
      headerName: "Project Name",
    },
    {
      field: "count",
      headerName: "Test Steps",
    },
    {
      field: "case_is_template",
      headerName: "Is Template",
    },
    {
      field: "u_name",
      headerName: "Created By",
    },
    {
      field: "test_type",
      headerName: "Type",
    },
    {
      field: "aciton",
      headerName: "Actions",
      renderCell: (params) => (
        <>
          <Tooltip title="Run Job">
            <PlayCircleOutlineIcon className="action-icon-brand" onClick={() => navigateToRunJob(params.row)}  />
          </Tooltip>
          <Tooltip title="Users">
            <DeleteIcon className="action-icon-delete" onClick={() => deleteTestCase(params.row)} />
          </Tooltip>
        </>
      ),
    },
  ];
  const [width, setWidth] = useState(100/columns.length + '% !important');
  const tableRows = allTestCasesList?.map((row, index) => {
    return {...row, id:index+1}
  });

  const handletypechange = (event) => {
    setSelectedtype(event.target.value);
    const { sort, filters } = extractSortAndFilterParams(sortModels, filterModels);
      getAllTestCases(paginationModals.page, paginationModals.pageSize, sort.field, sort.sort,JSON.stringify(searchQuery),event.target.value);
};

  useEffect(() => {
    updateTableHeight(tableRows?.length);
  }, [paginationModals.pageSize, tableRows]);

  return (
    <div>
      <Card
        raised
        sx={{
          minWidth: "100%",
          overflowX: "auto"  
        }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
          <Typography variant="h5">
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <label style={{ marginRight: '20px' }}>All Test Cases</label>
              <Select
                style={{ width: '15%', height: '40px' }}
                onChange={handletypechange}
                value={selectedtype}
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="Manually">Manually</MenuItem>
                <MenuItem value="AI">AI</MenuItem>
                <MenuItem value="Recorded">Recorded</MenuItem>
              </Select>
              <Box sx={{ flexGrow: 1 }} /> {/* This will push the Search and Button to the right */}
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Search onChangeQuery={handleSearchChange} value={searchQuery} />
                <Button onClick={() => navigateToAddScreen(state)}>+ Add Testcase</Button>
              </Box>
            </Box>
          </Typography>
        </Box>
        {/* <Typography variant="h5" sx={{
          p: 2
        }}>
        <label style={{ marginRight: '20px' }}>All Testcases</label>
          
          <Select  style={{width: '15%',  height: '40px' }}  onChange={handletypechange} value={selectedtype} >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Manually">Manually</MenuItem>
            <MenuItem value="AI">AI</MenuItem> 
            <MenuItem value="Recorded">Recorded</MenuItem>    
                            </Select>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          <Search onChangeQuery={handleSearchChange} value={searchQuery} />
          <Button onClick={() => navigateToAddScreen(state)}>+ Add Testcase</Button>
        </Box>
          
        </Typography>  */}
         
        {/* <Search sx={"float: right"} onChangeQuery={handleSearchChange} value={searchQuery}/>
          <Button sx={"float: right"} onClick={() => navigateToAddScreen(state)}> + Add Testcase</Button> */}


        <Divider></Divider>
        <CardContent>
        <div style={{ height: tableHeight, width: '100%' }}>
          <ServerPaginationGridNoRowCount
              setPaginationModals={setPaginationModals}
              paginationModals={paginationModals}
              rowsData={tableRows && tableRows.length ? tableRows : []}
              total={tableRows && testCount ? testCount : 0}
              columns={columns}
              columnWidth={width}
              setSortModels={setSortModels}
              sortModels={sortModels}
              setFilterModels={setFilterModels}
              filterModels={filterModels}
            />
          </div>
      </CardContent>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => ({
  testCaseList: state.auth?.allTestCasesList?.rows,
  allTestCasesList: state.auth?.allTestCasesList?.rows,
  testCount: state.auth?.allTestCasesList?.count
});

const mapDispatchToProps = {
  testCaseList,
  createTestCase,
  getAllTestCases,
  deleteTestCaseById
};

export default connect(mapStateToProps, mapDispatchToProps)(AllTestCases);