import React, { useState, useEffect } from 'react';
import {
    Card, CardContent, Button, Tooltip, Box, Modal , Typography
} from '@mui/material';
import { connect } from "react-redux";
import DownloadIcon from '@mui/icons-material/Download';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { modalPopUpStyleLarge } from '../../styles/getLPTheme';
import ServerPaginationGridNoRowCount from "../../components/Table/DataGrid";
import { ENV_VAR } from '../../common.js';
import { getStoredData } from '../../redux/actions/files';
import extractSortAndFilterParams from '../../components/Table/filterSortParams';
import Divider from "@mui/material/Divider";
import {formatDate} from '../../components/DateFormatter.jsx';
import '../../styles/common.css';
import '../../styles/custom.css';
import '../../styles/files.css';

function StoredData({ getStoredData }) {
    const [fileList, setFilelist] = useState([]);
    const [open, setOpen] = useState(false);
    const [files, setFileId] = useState();
    const [viewerUrl, setViewerUrl] = useState('');
    const [count, setCount] = useState(0);
    const [paginationModals, setPaginationModals] = useState({
        page: 0,
        pageSize: 10,
    });
    const [sortModels, setSortModels] = useState([]);
    const [filterModels, setFilterModels] = useState({ items: [] });
    
    const columns = [
        { field: "case_name", headerName: "Testcase" },
        { field: "step_name", headerName: "Step name" },
        { field: "store_created_at", headerName: "Creation Date" },
        { field: "u_name", headerName: "Created By" },
        {
            field: "Actions",
            headerName: "Actions",
            resizable: false,
            renderCell: (params) => (
                <>
                    <Tooltip title="Download">
                        <DownloadIcon className="action-icon" onClick={() => downloadFile(params.row)} />
                    </Tooltip>
                    <Tooltip title="View">
                        <RemoveRedEyeIcon className="action-icon" onClick={() => handleOpen(params.row)} />
                    </Tooltip>
                </>
            ),
        },
    ];

    const [width, setWidth] = useState(100/columns?.length + '% !important');

    useEffect(() => {
        const { sort, filters } = extractSortAndFilterParams(sortModels, filterModels);
        getStoredData(paginationModals.page, paginationModals.pageSize, sort.field, sort.sort,JSON.stringify(filters)).then((res) => {
            setCount(res.count);
            const dataWithIds = res?.rows.map((row, index) => ({
                ...row,
                case_name: row.case_name,
                store_created_at: formatDate(row.store_created_at),
                u_name: row.u_name,
                id: row.store_id, // Ensure each row has a unique 'id' property
            }));
            setFilelist(dataWithIds);
        });
    }, [getStoredData , paginationModals.page , paginationModals.pageSize]);

    
    
    const handleOpen = (row) => {
        let url = `${ENV_VAR.API_HOST}/${row.store_filepath}`;
        let googleDocsUrl = '';

        if (row.f_ext === 'xlsx' || row.f_ext === 'xls' || row.f_ext === 'xlsb' || row.f_ext === 'xlsm') {
            googleDocsUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${url}`;
        } else {
            googleDocsUrl = `https://docs.google.com/viewer?url=${url}&embedded=true`;
        }
        setViewerUrl(googleDocsUrl);
        setFileId(row);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const downloadFile = (row) => {
        const link = document.createElement('a');
        link.href = `${ENV_VAR.API_HOST}/${row.store_filepath}`;
        link.download = row.store_filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className='marginTop'>
            {/* <h3 className='label-bold'>Steps Stored Data List</h3>
            <br /> */}
                <Card
                    raised
                    sx={{
                        minWidth: "100%",
                        overflowX: "auto"  // Enable scrolling
                    }}
                >
                    <Typography variant="h5"  sx={{
                      p:2
                    }}>
                            Stored Data List
                    </Typography>

                <Divider></Divider>
                    <CardContent>
                            <ServerPaginationGridNoRowCount
                                setPaginationModals={setPaginationModals}
                                paginationModals={paginationModals}
                                rowsData={fileList}
                                total={count}
                                columns={columns}
                                columnWidth={width}
                                setSortModels={setSortModels}
                                sortModels={sortModels}
                                setFilterModels={setFilterModels}
                                filterModels={filterModels}
                            />
                    </CardContent>
                </Card>
            
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="viewer-modal-title"
                aria-describedby="viewer-modal-description">
                <Box component="form"
                    sx={{
                        ...modalPopUpStyleLarge, '& > :not(style)': { m: 1 },
                    }}
                    noValidate
                    autoComplete="off">
                    <div className="modal-header">
                        <h4>{files?.store_filename}</h4>
                    </div>
                    <div className='viewer-container'>
                        <iframe
                            src={viewerUrl}
                            title="Document Viewer"
                            width="100%"
                            height="600px"
                        ></iframe>
                    </div>
                    <div className="modal-footer">
                        <Button onClick={handleClose} className="modalButton modalCloseButton">Close</Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

const mapStateToProps = (state) => ({
    filesList: state?.files?.filesList
});

const mapDispatchToProps = {
    getStoredData,
};

export default connect(mapStateToProps, mapDispatchToProps)(StoredData);
