import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Button from "@mui/material/Button";
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from "react-router-dom";
import WbSunnyRoundedIcon from '@mui/icons-material/WbSunnyRounded';
import ModeNightRoundedIcon from '@mui/icons-material/ModeNightRounded';
import LogoutIcon from '@mui/icons-material/Logout';
import Notification from '../components/Notification.jsx';
import moment from "moment";
import "moment-timezone";
import ToggleColorMode from './ToggleColorMode';
import CloseIcon from '@mui/icons-material/Close';


const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function Header({ mode, toggleColorMode, open, handleDrawerOpen, NotificationList,MarkAsRead}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [profile, setProfile] = useState('');
  const [userName, setUserName] = useState(null);
  const navigate = useNavigate();
  const [notification, setNotification] = useState();
  const [userTimezone, setUserTimezone] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const uname = JSON.parse(localStorage.getItem("authentication"))
    ? JSON.parse(localStorage.getItem("authentication"))["name"]
    : "";
  const user = JSON.parse(localStorage.getItem("authentication"));

  useEffect(() => {
    if (user) {
      setUserName(user.name);
      setUserTimezone(user.timezone);
    }
  }, [user]);

  // useEffect(() => {
  //   setNotification(NotificationList);
  // }, [NotificationList]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // console.log("in Header",notification)
  const handleClose = () => {
    // navigate("/user-profile");
    setAnchorEl(null);
  };

  const logout = () => {
    localStorage.removeItem("authentication");
    localStorage.removeItem("token");
    window.location.assign("/signin");
  };

  useEffect(() => {
    if (localStorage.getItem("token") !== null && localStorage.getItem("token").length > 0) {
      const user = JSON.parse(localStorage.getItem("authentication"));
      setProfile(user);
      navigate('/dashboard');
    } else {
      logout();
    }
  }, []);

  const [greeting, setGreeting] = useState("Hello");

  useEffect(() => {
    const timezone = userTimezone || moment.tz.guess() || "UTC";
    const currentTime = moment.tz(timezone).hours();

    if (currentTime >= 4 && currentTime < 12) {
      setGreeting("Good Morning");
    } else if (currentTime >= 12 && currentTime < 17) {
      setGreeting("Good Afternoon");
    } else {
      setGreeting("Good Evening");
    }
  }, [userTimezone]);

  const handleHelpClick = () => {
    setModalOpen(true);
    setAnchorEl(null);
  };

  const handleThirdParty = () => {
    navigate('/JiraAccount');
    setAnchorEl(null);
  };

  const handleProfile = () => {
    navigate('/user-profile');
    setAnchorEl(null);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <AppBar position="fixed" open={open}>
        <Toolbar
          variant="regular"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexShrink: 0,
          }}
        >
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              flexGrow: 1,
            }}
          >
            <Typography variant="h6" color={"white"}>
              {greeting}, {uname}
            </Typography>
          </Box>

          <Box
             sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              flexGrow: 0,
            }}
            // sx={{
            //   display: "flex",
            //   alignItems: "center",
            //   flexGrow: 1,
            //   justifyContent: "flex-end",
            // }}
          >
            <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
            {/* <Notification dataList={notification}  style={{ cursor: 'pointer', marginLeft: 2 }} /> */}
            <Notification jobList={NotificationList} MarkAsRead={MarkAsRead} style={{ cursor: 'pointer', marginLeft: 2 }} />

            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              // sx={{ marginLeft: 2 }}
            >
              <Avatar
                sx={{ width: 30, height: 30 ,textTransform: "uppercase"}}
                alt={userName}
                src={`${process.env.REACT_APP_API_URL}/api/v1/user_image?token=${localStorage.getItem('token')}&timestamp=${Date.now()}`}
              />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <div
                style={{
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <Avatar
                    sx={{ width: 40, height: 40, marginBottom: "10px",textTransform: "uppercase", }}
                    alt={userName}
                    src={`${process.env.REACT_APP_API_URL}/api/v1/user_image?token=${localStorage.getItem('token')}&timestamp=${Date.now()}`}
                  />

                  <Typography variant="h6" color={"grey"}>
                    {uname}
                  </Typography>
                </Box>

                <MenuItem onClick={handleProfile} >
                  <Link style={{ textDecoration: 'none' }}>Profile</Link>
                </MenuItem>
                <MenuItem onClick={handleHelpClick}>
                  <Link>Help</Link>
                </MenuItem>
                <MenuItem onClick={handleThirdParty}>
                  <Link>Third Party</Link>
                </MenuItem>
                <MenuItem onClick={logout}>Logout</MenuItem>
              </div>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>

      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        aria-labelledby="help-modal-title"
        aria-describedby="help-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '70%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleModalClose}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: 'grey',
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="help-modal-title" variant="h6" component="h2">
            Help Document
          </Typography>
          <embed
            src="https://apps.torqueautomation.co/docs/Torque_Tool_Docs.pdf"
            type="application/pdf"
            width="100%"
            height="600px"
          />
        </Box>
      </Modal>
    </>
  );
}
