import { Button, Card,Modal,Typography } from "@mui/material";
import { connect } from "react-redux";
import React,{ useEffect, useState ,useRef} from "react";
import io from "socket.io-client";
import { useLocation, useNavigate } from "react-router-dom";
import { getSuitesTestCaseId, runSuiteJob , getTestCaseNames } from "../../redux/actions/suites";
import { StopJob } from "../../redux/actions/stop";
import {modalPopUpStyleLarge } from '../../styles/getLPTheme';
import Box from "@mui/material/Box";
import "../../styles/project.css";
import "../../styles/common.css";
import ENV_VAR from "../../common";
const SOCKET_SERVER_URL = `${ENV_VAR.API_HOST}`;

export const RunSuites = ({ ongoingSuites, setOngoingSuites, runSuiteJob, suitesTestList, getTestCaseNames, StopJob }) => {
  const socketRef = useRef(null);
  const { state } = useLocation();
  const [suite_Test_List, setSuiteTestList] = useState([]);
  const [progress, setProgress] = useState(null);
  let [items, setItems] = useState("");
  const [suiteId, setSuiteId] = useState(null);
  const [Stoptask, setStoptask] = useState(null);
  const [taskId, setTaskId] = useState(null);
  const [Image, setImage] = useState(null);
  const [Message, setMessage] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [progressBarColor,setProgressBarColor] = useState("ADD8E6")
  const handleClose = () => {
    setOpen(false);
    setIsVisible(true);
  };
  const updatePopUp = () => {
    setOpen(true);
    setIsVisible(false);

  }

  useEffect(() => {
    getSuitesTestCaseId(state.suite_id);
    getTestCaseNames(state.suite_id).then((res) => {
      if(res && res.length > 0){
        const sortedStepsList = res?.sort(
          (a, b) => a.stc_prioprity - b.stc_prioprity
        );
        setSuiteTestList(sortedStepsList);
      }
    })
  }, []);

  useEffect(() => {
    if (suitesTestList && suitesTestList?.length > 0) {
      const sortedStepsList = suitesTestList?.sort(
        (a, b) => a.stc_prioprity - b.stc_prioprity
      );
      
      setSuiteTestList(sortedStepsList);
    }
  }, [suitesTestList]);

 
  useEffect(() => {
    // Initialize socket connection
    socketRef.current = io(SOCKET_SERVER_URL, {
      withCredentials: true,
    });
 

    const handleImgToReact = (data) => {
      setIsVisible(true);
      if (taskId === data.data[1]) {
        setImage("data:image/png;base64, " + data.data[0]);
      }
    };

    const handleMessageToReact = (data) => {
      if (suiteId === data.data[2] && taskId === data.data[3]) {
        setStoptask(data.data[3]);
        setProgress(data.data[0]);
        getText(data.data[1]);
        setItems(data.data[1]);
        if (data.data[0] === 100) {
          socketRef.current.off("message_to_react", handleMessageToReact);
          socketRef.current.off("img_to_react", handleImgToReact);
          socketRef.current.disconnect();
          setIsDisabled(false);
          setOpen(false);
          setIsVisible(false);
          setOngoingSuites(null);
          setProgressBarColor("#4CBB17");
        }
      }
    };
    // Set up event listeners
    socketRef.current.on("img_to_react", handleImgToReact);
    socketRef.current.on("message_to_react", handleMessageToReact);

    // Clean up on unmount
    return () => {
      if (socketRef.current) {
        socketRef.current.off("img_to_react", handleImgToReact);
        socketRef.current.off("message_to_react", handleMessageToReact);
        socketRef.current.disconnect();
      }
    };
  }, [taskId, suiteId, SOCKET_SERVER_URL]);

  const parseLogString = (items) => {
    const lines = items?.split("</br>");
    return lines?.map((line, index) => (
      <li key={index} dangerouslySetInnerHTML={{ __html: line }} />
    ));
  };

  const getText = (items) => {
    const cleanedItems = items.replace(/running Step :/g, 'Step :');
    const lines = cleanedItems.split('</li>');
    const lastItem = lines[lines.length - 2]; 
    const startIndex = lastItem?.lastIndexOf('>') + 1; 
    const liText = lastItem?.substring(startIndex)?.trim(); 
    setMessage(liText)
  };

  const startJobApi = () => {
    setOngoingSuites(null);
    if (socketRef.current) {
      socketRef.current.connect();
    };
    // setIsVisible(true);
    setIsDisabled(true);
    setImage(null);
    runSuiteJob(state.suite_id).then((res) => {
      setTaskId(res.taskId)
    });
    setSuiteId(state.suite_id);
    setProgress(0);
    setItems("");
    setStoptask('');
  };
  const stopJobApi = () => {
    StopJob(Stoptask,"TestSuite");
  };

  if (progress===100){
    if (socketRef.current) {
      socketRef.current.disconnect();
    }
  }
  // const progressBarColor = progress === 100 ? "#4CBB17" : "#ADD8E6";
  useEffect(() => {
    if (taskId && suiteId) {
      if (progress===100){
        setIsDisabled(false);
        setOngoingSuites(null);
      } else { 
        console.log("++++++", JSON.stringify({taskId:taskId, suiteId:suiteId, progress:progress, items:items, Image:Image, Stoptask:Stoptask}));
        setIsDisabled(true);
        setOngoingSuites(JSON.stringify({taskId:taskId, suiteId:suiteId, progress:progress, items:items, Image:Image, Stoptask:Stoptask, state:state}));
      }
    }
  }, [taskId, suiteId, progress, items, Image, state]);
  useEffect(() => {
    console.log("ONGOING SUITS==>", ongoingSuites);
    if (ongoingSuites) {
      let opr = JSON.parse(ongoingSuites); 
      setTaskId(opr?.taskId);
      setSuiteId(opr?.suiteId);
      setProgress(opr?.progress);
      setItems(opr?.items);
      setImage(opr?.Image);
      setStoptask(opr?.Stoptask);
    }
  }, [ongoingSuites]);
  return (
    <div className="marginTop">
      <h3 className="label-bold">Suite : {state.suite_name}</h3>
      <Card className="padding-runJob marginTop">
        <h3 className="label-bold">Test cases</h3>
        <Box className="marginTop">
          {suite_Test_List?.filter(row => row.status).map((row, index) => (
            <li key={index}>{row.case_name}</li>
          ))}
          <div className="marginTop">
            <Button onClick={startJobApi} disabled={isDisabled}>Start Job</Button>
            <Button onClick={() => navigate("/suites")} colorStyle="cancel">
              Back
            </Button>
          </div>
        </Box>
        <br />
        {Stoptask && progress <=90? <Box>
          <Button onClick={stopJobApi} colorStyle="cancel">Stop Job</Button>
        </Box>:null}
        <Box style={{ position: 'relative' }}>
          {isVisible && Image && (
            <div style={{ position: 'absolute', top: -85, right: 0, border: '2px solid #ced4da', cursor: 'pointer' }}>
              <div onClick={updatePopUp}>
                <img src={Image} alt="screenshot" style={{ height: '200px', width: '400px', padding: '10px' }} />
              </div>
            </div>
          )}

        </Box>
        
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        className="modalContainer">
        <Box
           sx={{
            ...modalPopUpStyleLarge, '& > :not(style)': { m: 1 },
        }}
        noValidate
        autoComplete="off">
         
          <div className="modal-header">
           <img  src={Image} alt="Screenshot"  style={{  maxWidth: '90%', height: 'auto' }} />
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">{Message}</Typography>
            <Typography gutterBottom textAlign="right" variant="h5">
              <Button onClick={handleClose} className="modalButton modalCloseButton">Close</Button>
            </Typography>
          </div>
        </Box>
      </Modal>
        <br />
          <br />
          <br />
          <br />
        
        <div className="marginTop">
          <div className="d-flex justify-content-between align-items-center mb-1 max-width-progress-wrap">
            <p className="text-success">
              {taskId !== null && taskId !== undefined ? (
                `${progress}%`
              ) : (
                <p c>Waiting for progress to start...</p>
              )}
            </p>
          </div>
          {taskId !== null && taskId !== undefined ? (
            <div
              className="progress "
              role="progressbar"
              style={{ height: "30px" }}
              aria-label="Success example"
              aria-valuenow="80"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <div
                className="progress-bar progress-bar-striped "
                style={{
                  width: `${progress}%`,
                  backgroundColor: progressBarColor,
                  height: "100%",
                  transition: "width 0.5s ease-in-out",
                }}
              ></div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div
          style={{
            width: `${progress}%`,
            backgroundColor: "green",
            height: "100%",
          }}
        ></div>
        {taskId !== null && taskId !== undefined ? (
          <div>
            <ul className="bullet-line-list">{parseLogString(items)}</ul>
            <div></div>
          </div>
        ) : (
          ""
        )}
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  suitesTestList: state?.suites.suiteListById,
  suiteStatus: state?.suites.suiteRunStatus,
});

const mapDispatchToProps = {
  runSuiteJob,
  getSuitesTestCaseId,
  getTestCaseNames,
  StopJob
};

export default connect(mapStateToProps, mapDispatchToProps)(RunSuites);
