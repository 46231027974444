import React from 'react';
import { Button, Typography, Box, Card } from "@mui/material";
import { modalPopUpStyleLarge } from '../styles/getLPTheme';
import Modal from "@mui/material/Modal";

const OngoingOperation = ({ stopJobApi, Image, progress, parseLogString, items, Message, handleClose, updatePopUp, isVisible, open }) => {
  return (
    <div className="marginTop">
      <Card className="padding-runJob marginTop">
        <Box>
          <Button onClick={stopJobApi} colorStyle="cancel">Stop Job</Button>
        </Box>
        <Box style={{ position: 'relative' }}>
          {isVisible && Image && (
            <div style={{ position: 'absolute', top: -85, right: 0, border: '2px solid #ced4da', cursor: 'pointer' }}>
              <div onClick={updatePopUp}>
                <img src={Image} alt="screenshot" style={{ height: '200px', width: '400px', padding: '10px' }} />
              </div>
            </div>
          )}
        </Box>
        
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          className="modalContainer">
          <Box
            sx={{
              ...modalPopUpStyleLarge, '& > :not(style)': { m: 1 },
            }}
            noValidate
            autoComplete="off">
            <div className="modal-header">
              <img src={Image} alt="Screenshot" style={{ maxWidth: '100%', height: '100%' }} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6">{Message}</Typography>
              <Typography gutterBottom textAlign="right" variant="h5">
                <Button onClick={handleClose} className="modalButton modalCloseButton">Close</Button>
              </Typography>
            </div>
          </Box>
        </Modal>
        
        <div>
          <br />
          <br />
          <br />
          <br />
          <div className="d-flex justify-content-between align-items-center mb-1 max-width-progress-wrap">
            <p className="text-success">
              {progress}%
            </p>
          </div>
          <div
            className="progress "
            role="progressbar"
            style={{ height: "30px" }}
            aria-label="Success example"
            aria-valuenow="80"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div
              className="progress-bar progress-bar-striped "
              style={{
                width: `${progress}%`,
                backgroundColor: progress === 100 ? "#4CBB17" : "#ADD8E6",
                height: "100%",
                transition: "width 0.5s ease-in-out",
              }}
            ></div>
          </div>
          <div>
            <ul class="bullet-line-list">{parseLogString(items)}</ul>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default OngoingOperation;
